import { BooleanField } from "../components/BooleanField";
import { MemberBox } from "@logora/parliament.parliament_member.member_box";
import { ActeurIcon } from "@logora/parliament.icons.regular_icons";
import { formatChambre, formatGroupeParlementaire } from "../formatters";

const formatFullName = (field, item) => {
    return <MemberBox firstName={item.prenom} lastName={item.nom} imageUrl={item.urlImage} />
}

const formatActif = (field, item) => {
    return <BooleanField condition={item.actif} />
}

const formatCirconscription = (field, item) => {
    return item.circonscription?.libelle
}

const formatCommission = (field, item) => {
    return item.commissionPermanente?.libelle
}

const acteursFields = {
    'chambre': { label: "Chambre", field: "chambre", format: formatChambre },
    'fullName': { label: "Nom complet", field: "fullName", sort: "nom", order: 'asc', format: formatFullName, defaultSort: true },
    'groupeParlementaire': { label: "Groupe politique", field: "groupeParlementaire", include: "groupeParlementaire", format: formatGroupeParlementaire },
    'circonscription': { label: "Cirsconscription", field: "circonscription", include: "circonscription", format: formatCirconscription },
    'commissionPermanente': { label: "Commission permanente", field: "commissionPermanente", include: "commissionPermanente", format: formatCommission },
    'themes': { label: "Sujets favoris", field: "themes" },
    'actif': { label: "Actif", field: "actif", sort: "actif", format: formatActif }
};

const acteursFilters = [
    {
        field: "chambre", label: "Chambre", defaultValue: null, type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: "AN", label: 'Assemblée' },
            { value: "SN", label: 'Sénat' },
        ]
    },
    {
        field: "actif", label: "Actif",  defaultValue: true, type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: true, label: 'Actif' },
            { value: false, label: 'Non actif' }
        ]
    }
];

export const acteurData = {
    key: "acteurs",
    name: "Acteurs",
    filters: acteursFilters,
    filterMapping: { "acteurRefUid": "uid", "organeRefUid": "groupeParlementaireUid" },
    fields: acteursFields,
    icon: <ActeurIcon height={20} width={20} />
}