// in src/App.js
import React from 'react';
import {
    Admin,
    Resource,
    resolveBrowserLocale,
    combineDataProviders
} from 'react-admin';
import { IntlProvider } from 'react-intl';
import { ModalProvider } from '@logora/debate.dialog.modal';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { UserList, UserEdit, UserIcon } from './users';
import { SettingsEdit, SettingsIcon } from './settings';
import { ExportIcon, ExportList } from './exports';
import { IconProvider } from '@logora/debate.icons.icon_provider';
import { useAuthInterceptor } from '@logora/debate.auth.use_auth';
import { axiosClient } from './axiosClient';
import * as regularIcons from '@logora/debate.icons.regular_icons';
import theme from './theme';
import MyLayout from './Layout';
// dataProviders
import authProvider from './authProvider';
// i18n
import polyglotI18nProvider from 'ra-i18n-polyglot';
import locales from './i18n';
import { QueryClientProvider } from './utils/QueryClientProvider';
import { useActiveModulesContext } from './utils/ActiveModulesContext';
// Imported routes
import {
    routes as debateRoutes,
    debateDataProvider,
    Dashboard as DebateDashboard
} from './debate';
import { routes as sourceRoutes } from './source';
import { routes as moderationRoutes } from './moderation';
import { routes as authenticationRoutes, Auth } from './authentication';
import { routes as accountRoutes, accountDataProvider } from './account';
import {
    routes as parliamentRoutes,
    parliamentDataProvider,
    localesParliament,
    Dashboard as ParliamentDashboard
} from './parliament';
import {
    socialNetworksRoutes,
    socialNetworksDataProvider,
    AuthPage,
    Dashboard as SocialNetworksDashboard
} from './social_networks';

const messages = {
    fr: {
        ...locales.french,
        resources: {
            ...locales.french.resources,
            ...localesParliament.resources
        }
    },
    en: locales.english,
    es: locales.spanish,
    de: locales.german,
    it: locales.italian,
    pt: locales.portuguese,
    pt_BR: locales.portugueseBR
};

const i18nProvider = polyglotI18nProvider(
    (locale) => (messages[locale] ? messages[locale] : messages.en),
    resolveBrowserLocale()
);

const defaultDataProvider = Object.assign(
    debateDataProvider,
    accountDataProvider
);

const dataProvider = combineDataProviders((resource) => {
    if (window.location.hash.includes('parliament')) {
        if (resource === 'alerts') {
            return defaultDataProvider;
        }
        return parliamentDataProvider;
    } else if (
        resource === 'moderable_contents' ||
        resource === 'integrations' ||
        resource === 'batches' ||
        resource === 'moderable_sources' ||
        resource === 'posts'
    ) {
        return socialNetworksDataProvider;
    } else {
        return defaultDataProvider;
    }
});

const App = () => {
    const {
        isDebateSpaceActive,
        isSourceActive,
        isSocialModerationActive,
        isParliamentActive
    } = useActiveModulesContext();

    const tokenKey =
        process.env.NODE_ENV === 'production'
            ? 'logora_admin_token'
            : 'logora_admin_token_staging';

    useAuthInterceptor(
        axiosClient,
        process.env.REACT_APP_API_AUTH_URL,
        tokenKey
    );

    const getDashboard = () => {
        if (isDebateSpaceActive) {
            return DebateDashboard;
        }
        if (isSocialModerationActive) {
            return SocialNetworksDashboard;
        }
        if (isParliamentActive) {
            return ParliamentDashboard;
        }
    };

    return (
        <IntlProvider locale="fr" onError={() => null}>
            <IconProvider library={regularIcons}>
                <ModalProvider>
                    <BrowserRouter>
                        <QueryClientProvider>
                            <Routes>
                                <Route
                                    path="/integrations/:provider/callback"
                                    element={<AuthPage />}
                                />
                            </Routes>
                        </QueryClientProvider>
                    </BrowserRouter>

                    <Admin
                        disableTelemetry
                        loginPage={Auth}
                        dashboard={getDashboard()}
                        layout={MyLayout}
                        i18nProvider={i18nProvider}
                        authProvider={authProvider}
                        dataProvider={dataProvider}
                        theme={theme}
                    >
                        {isDebateSpaceActive && (
                            <Resource
                                name="users"
                                list={UserList}
                                edit={UserEdit}
                                icon={UserIcon}
                            />
                        )}
                        {(isDebateSpaceActive || isSocialModerationActive) && (
                            <Resource
                                name="settings"
                                list={SettingsEdit}
                                icon={SettingsIcon}
                            />
                        )}
                        <Resource
                            name="exports"
                            list={ExportList}
                            icon={ExportIcon}
                        />
                        {isDebateSpaceActive && debateRoutes}
                        {parliamentRoutes}
                        {moderationRoutes}
                        {isSourceActive && isDebateSpaceActive && sourceRoutes}
                        {accountRoutes}
                        {socialNetworksRoutes}
                        {authenticationRoutes}
                    </Admin>
                </ModalProvider>
            </IconProvider>
        </IntlProvider>
    );
};

export default App;
