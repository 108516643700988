import React from 'react';
import { MetricDashboardBox } from './MetricDashboardBox';
import { ThumbUp } from '@mui/icons-material';
import { useTranslate } from 'react-admin';

export function LikeMetricDashboardBox() {
    const translate = useTranslate();

    return (
        <MetricDashboardBox
            title={translate('pos.social_networks.dashboard.metrics.likes')}
            icon={ThumbUp}
            metric="2000"
            trend="down"
            trendValue="17%"
            comingSoon
        />
    );
}
