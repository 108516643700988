import React from 'react';
import { DashboardBox } from '../dashboard/DashboardBox';
import { Article } from '@mui/icons-material';
import { useGetList, useTranslate } from 'react-admin';
import { PostCard } from './PostCard';
import { Post } from './post';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Stack } from '@mui/material';
import './PostDashboardBox.scss';
import emptyBoxImg from '../assets/box-empty.svg';
import noDataImg from '../assets/no-data.svg';

export function PostDashboardBox() {
    const { data: posts } = useGetList<Post>(
        'posts',
        {},
        { staleTime: 5 * 60 * 1000 }
    );

    const translate = useTranslate();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: posts && posts.length > 1 ? 2 : 1,
        slidesToScroll: 2,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        vertical: true
    };

    return (
        <DashboardBox
            title={translate('pos.social_networks.dashboard.top_posts')}
            icon={Article}
        >
            {posts && posts.length > 0 ? (
                <Box marginBottom={3}>
                    <Slider {...settings}>
                        {posts.map((post, i) => (
                            <PostCard key={post.id} post={post} rank={i + 1} />
                        ))}{' '}
                    </Slider>
                </Box>
            ) : (
                <Stack
                    sx={{
                        height: '100%',
                        placeItems: 'center',
                        justifyContent: 'center',
                        gap: 3
                    }}
                >
                    <p>{translate('pos.social_networks.no_post')}</p>
                </Stack>
            )}
        </DashboardBox>
    );
}
