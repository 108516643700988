import { Box, Icon, Stack } from '@mui/material';
import React from 'react';

export function DashboardBox({
    title,
    icon,
    children,
    menu,
    borderRadius = 'md'
}: {
    title: string;
    icon: React.ComponentType;
    children?: React.ReactNode;
    menu?: React.ReactNode;
    borderRadius?: 'sm' | 'md';
}) {
    return (
        <Box
            border={1}
            borderColor="var(--clr-neutral-800)"
            borderRadius={borderRadius == 'sm' ? '6px' : '12px'}
            padding="18px"
            width="100%"
            display="flex"
            flexDirection="column"
        >
            <Stack
                direction="row"
                gap={2}
                alignItems="center"
                justifyContent="space-between"
                marginBottom={2}
            >
                <Stack direction="row" gap={1} alignItems="center">
                    <Icon
                        component={icon}
                        sx={{
                            color: 'var(--clr-neutral-0)',
                            fontSize: '1.75rem'
                        }}
                    />
                    <h2>{title}</h2>
                </Stack>

                {menu}
            </Stack>

            <Box flex={1}>{children}</Box>
        </Box>
    );
}
