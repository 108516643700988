import React, { useEffect, useRef, useState } from 'react';
import { MenuSharp, ChevronRight } from '@mui/icons-material';
import { Alinea } from '../Alinea';
import { capitalizeFirstLetter } from '../../formatters';
import styles from './TexteBox.module.scss';

const TexteBox = ({ document, onAmendementButtonClick = () => { } }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  let { alineas = [], subdivisions = [] } = document || {};
  const containerRef = useRef(null);
  const menuRef = useRef(null);

  subdivisions = subdivisions.filter(s => s.type !== "ExposeMotifs").sort((a, b) => a.sequence - b.sequence)

  const scrollToSubdivision = (hash) => {
    const element = containerRef.current.querySelector(`[data-hash="${hash}"]`);
    if (!element) return;
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    setIsMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.header}>
        <span className={styles.topTexte}>Texte</span>
        {subdivisions.length > 0 &&
          <button
            className={styles.summaryButton}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span>Sommaire</span>
            <MenuSharp fontSize='small' />
          </button>
        }
        {subdivisions.length > 0 && isMenuOpen && (
          <div ref={menuRef} className={styles.menu}>
            {subdivisions.map(subdivision => (
              <button
                key={subdivision.hash}
                onClick={() => scrollToSubdivision(subdivision.hash)}
                className={`${styles.menuItem} ${styles[subdivision.type?.toLowerCase()]}`}
              >
                {subdivision.titre}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className={styles.content}>
        <h3 className={styles.formule}>
          {capitalizeFirstLetter(document?.titrePrincipal)}
        </h3>

        {subdivisions.length > 0 ?
          <div className={styles.subdivisions}>
            {subdivisions.map((subdivision, index) => (
              <div
                key={subdivision.hash}
                className={styles.subdivision}
                data-hash={subdivision.hash}
              >
                <h5 className={styles.articleTitle}>
                  {subdivision.titre}
                </h5>
                <h5 className={styles.articleTitle}>
                  {subdivision.sousTitre}
                </h5>

                <div className={styles.actionButtons}>
                  <button onClick={() => onAmendementButtonClick(subdivision)} className={`${styles.amendmentButton} ${subdivision.nombreAmendements > 0 ? 'active' : ''}`}>
                    {subdivision.nombreAmendements === 0 && "Aucun amendement déposé"}
                    {subdivision.nombreAmendements === 1 && "Voir l'amendement déposé"}
                    {subdivision.nombreAmendements > 1 && `Voir les ${subdivision.nombreAmendements} amendements déposés`}
                    {subdivision.nombreAmendements > 0 && <ChevronRight fontSize='small' />}
                  </button>
                </div>

                <div className={styles.alineas}>
                  {alineas
                    .filter(a => a.articleRefHash === subdivision.hash)
                    .sort((a, b) => a.numero - b.numero)
                    .map((alinea) => (
                      <Alinea
                        key={alinea.numero + 1}
                        number={alinea.numero + 1}
                        text={alinea.texte}
                      />
                    ))}
                </div>
              </div>
            ))}
          </div>
          :
          <i className={styles.empty}>Le contenu du texte n'est pas disponible pour le moment</i>
        }
      </div>
    </div>
  );
};

export default TexteBox;