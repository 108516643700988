import React from 'react';
import { useRedirect } from 'react-admin';
import { Show } from '../components/show/Show';
import { Label } from '@logora/parliament.ui.label';
import { Box } from '@logora/parliament.ui.box';
import { formatDate } from '../formatters';
import { formatType, formatCodeCloture } from './questionsFields';
import { ChamberLabel } from '@logora/parliament.parliament_chamber.chamber_label';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import sanitizeHtml from 'sanitize-html'
import styles from "./QuestionShow.module.scss"

const QuestionDetails = ({ resource }) => {
    const redirectTo = useRedirect();

    const sanitize = (html) => {
        const sanitizeOptions = {
            allowedTags: ['i', 'sup', 'br', 'strong']
        };

        const sanitizedHtml = sanitizeHtml(html, sanitizeOptions)

        return { __html: sanitizedHtml }
    }

    return (
        <>
            <div className={styles.header}>
                <ChamberLabel chamber={resource.chambre} />
                <Label text={formatType(resource.type, resource)} />
            </div>
            <div className={styles.title}>
                {resource.titre}
            </div>
            <div className={styles.presentation}>
                <div className={styles.box}>
                    <Box title="Auteur" className={styles.boxContainer}>
                        <MemberBox
                            firstName={resource.acteurRef?.prenom}
                            lastName={resource.acteurRef?.nom}
                            imageUrl={resource.acteurRef?.urlImage}
                            parliamentGroup={{
                                groupName: resource.acteurRef?.groupeParlementaire?.libelle,
                                color: resource.acteurRef?.groupeParlementaire?.couleurAssociee
                            }}
                            className={styles.link}
                            onClick={() => redirectTo(`/parliament/acteurs/${resource.acteurRefUid}`)}
                        />
                    </Box>
                </div>
                <div className={styles.box}>
                    <Box title="Ministère intérrogé" className={styles.boxContainer}>
                        <div className={styles.link} onClick={() => redirectTo(`/parliament/organes/${resource.minIntRefUid}`)}>
                            <div>{resource.minIntRef?.libelleAbrege}</div>
                        </div>
                    </Box>
                </div>
                <div className={styles.box}>
                    <Box title="Date de dépôt" className={styles.boxContainer}>
                        <div>{formatDate("dateDepot", resource)}</div>
                    </Box>
                </div>
                <div className={styles.box}>
                    <Box title="Statut" className={styles.boxContainer}>
                        <div>{formatCodeCloture("etat", resource)}</div>
                    </Box>
                </div>
            </div>
            <div className={styles.corps}>
                {resource.type !== "QG" &&
                    <div className={styles.box}>
                        <Box title="Question" className={styles.boxContainer}>
                            <div>{resource.texteQuestion}</div>
                        </Box>
                    </div>
                }
                <div className={styles.box}>
                    <Box title={resource.type === "QG" ? "Intervention" : "Réponse"} className={styles.boxContainer}>
                        {resource.texteReponse ?
                            <div dangerouslySetInnerHTML={sanitize(resource.texteReponse)} />
                            :
                            <i>Pas de réponse pour l'instant</i>
                        }

                    </Box>
                </div>
            </div>
        </>
    )
}

export const QuestionShow = () => {
    return (
        <Show
            resource={"questions"}
            includes={["acteurRef.groupeParlementaire", "minIntRef"]}
            backLink={"/parliament/recherche/questions"}
            backText={"Voir toutes les questions"}
        >
            <QuestionDetails />
        </Show >
    );
};
