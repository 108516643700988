import React from 'react';
import { CircleOutlined, CheckCircle, MoreHoriz } from '@mui/icons-material';
import styles from './TimelineStep.module.scss';
import cx from 'classnames';

export const TimelineStep = ({ acteLegislatif, selectedActeUid, currentActeUid, label, onClickStep }) => {
  const getStatusClassName = () => {
    switch(status) {
      case "active":
        return styles.stepActive
      case "pending":
        return styles.stepPending
      case "complete":
        return styles.stepComplete
    }
  }

  const getStatus = () => {
    var now = new Date();

    if(acteLegislatif?.uid === currentActeUid) {
      return "active"
    }

    if (!acteLegislatif || new Date(acteLegislatif?.dateActe) > now) {
      return "pending"
    }
    return "complete"
  }

  const status = getStatus()
  const statusClassName = getStatusClassName()
  const isSelected = acteLegislatif?.uid === selectedActeUid

  return (
    <button
      className={cx(styles.step, statusClassName, { [styles.selected]: isSelected})}
      onClick={onClickStep}
      aria-label={label}
      role="listitem"
    >
      {status === 'complete' && <CheckCircle className={styles.stepIcon} />}
      {status === 'active' && <MoreHoriz className={styles.stepIcon} />}
      {status === 'pending' && <CircleOutlined className={styles.stepIcon} />}
      <span>{label}</span>
    </button>
  );
};
