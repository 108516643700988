import React from 'react';
import get from 'lodash/get';
import { useRecordContext } from 'react-admin';

const LinkField = ({ source, content, label }) => {
    const record = useRecordContext();

    return record ? (
        <a
            href={get(record, source)}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => e.stopPropagation()}
            style={{
                textDecoration: 'underline',
                color: "#5B7FF1"
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    alignItems: 'center'
                }}
            >
                {content}
            </div>
        </a>
    ) : null;
};

export default LinkField;
