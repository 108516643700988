import React from 'react';
import { MetricDashboardBox } from './MetricDashboardBox';
import { Chat } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import httpClient from '../../httpClient';
import { useTranslate } from 'react-admin';

type CommentCountMetric = {
    current_value: number;
    previous_value: number;
};

export function CommentMetricDashboardBox({
    providers,
    fromDate,
    toDate
}: {
    providers: string[];
    fromDate: string;
    toDate: string;
}) {
    const { data, isLoading } = useQuery<CommentCountMetric>({
        queryKey: ['comments_count', fromDate, toDate, providers],
        queryFn: async () => {
            const url = new URL(
                process.env.REACT_APP_SOCIAL_MODERATOR_URL +
                    `/statistics/comments_count`
            );

            url.searchParams.append('filter', 'provider');
            url.searchParams.append('from_date', fromDate);
            url.searchParams.append('to_date', toDate);

            if (providers.length > 0) {
                url.searchParams.append('providers', providers.join(','));
            }

            const response = await httpClient(url);

            return response && response.json;
        },
        staleTime: 5 * 60 * 1000
    });

    const translate = useTranslate();

    const getTrend = () => {
        if (!data) {
            return 'flat';
        }

        if (data.current_value === data.previous_value) {
            return 'flat';
        }

        return data.current_value > data.previous_value ? 'up' : 'down';
    };

    const getTrendValue = () => {
        if (!data) {
            return '0%';
        }

        const sign = data.current_value >= data.previous_value ? '+' : '-';

        return `${sign}${Math.abs(data.current_value - data.previous_value)}`;
    };

    return (
        <MetricDashboardBox
            title={translate('pos.social_networks.dashboard.metrics.comments')}
            icon={Chat}
            metric={data ? `${data.current_value}` : '0'}
            trend={getTrend()}
            trendValue={getTrendValue()}
        />
    );
}
