import React, { useState, useEffect } from 'react';
import { makeStyles } from "@mui/styles";
import { useTranslate } from 'react-admin';
import TextField from "@mui/material/TextField";

const useStyles = makeStyles((theme) => ({
    filters: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: "0.5rem",
        backgroundColor: "#FFF",
    },
    filterItem: {
        marginRight: "0.5em",
    },
    dateFilter: {
        marginRight: "15px",
        padding: "5px",
    },
    errorMessage: {
        color: "#d32f2f !important",
        fontSize: "0.75em !important",
        paddingTop: "5px"
    },
}));

// DATE FORMAT : date=2022-10-03~2022-10-21
const formatDate = (date) => {
    const yyyy = date.getFullYear();
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const dd = String(date.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
}

const thirtyDaysAgo = new Date();
thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

export const DashboardDatePicker = ({ onDateRangeChange }) => {
    const [startDate, setStartDate] = useState(formatDate(thirtyDaysAgo));
    const [endDate, setEndDate] = useState(formatDate(tomorrow));
    const classes = useStyles();
    const translate = useTranslate();

    useEffect(() => {
        onDateRangeChange(`${startDate}~${endDate}`);
    }, [startDate, endDate])

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={classes.filters}>
                <div className={classes.filterItem}>
                    <TextField
                        error={startDate > endDate || endDate < startDate}
                        value={startDate}
                        type='date'
                        onChange={(event) => setStartDate(event.target.value)}
                        className={classes.dateFilter}
                    />
                </div>
                <div className={classes.filterItem}>
                    <TextField
                        error={startDate > endDate || endDate < startDate}
                        value={endDate}
                        type='date'
                        onChange={(event) => setEndDate(event.target.value)}
                        className={classes.dateFilter}
                    />
                </div>
            </div>
            {(startDate > endDate || endDate < startDate) && <div className={classes.errorMessage}>{translate("pos.dashboard.date_error")}</div>}
        </div>
    );
}