import React, { useState, useEffect, useCallback } from "react";
import { Button, useRedirect, useTranslate, Loading } from "react-admin";
import { makeStyles } from "@mui/styles";
import { useSettingsContext } from "../../utils/SettingsProvider";
import { MetabaseDashboard } from "./MetabaseDashboard";
import { DashboardDatePicker } from "./DashboardDatePicker";
import DashboardTitle from "./DashboardTitle";
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DashboardDebates from "./DashboardDebates";
import FormSectionTitle from "../../utils/FormSectionTitle";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';

const useStyles = makeStyles((theme) => ({
	flex: { display: "flex" },
	chartBox: { display: "flex", width: "100%" },
	flexColumn: { display: "flex", flexDirection: "column", marginBottom: "1em" },
	leftCol: { flex: 1, marginRight: "1em" },
	rightCol: { flex: 1 },
	singleCol: { marginTop: "1em" },
	header: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginTop: "30px",
	},
	headerButton: {
		whiteSpace: "nowrap",
		border: "1px solid #5B7FF1 !important",
		padding: "17px !important",
		borderRadius: "4px !important"
	},
	primaryButton: {
		color: "white !important",
		background: "#5B7FF1 !important"
	},
	loader: {
		marginTop: "1.5em",
	},
	numericStatsBox: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center"
	},
	resultsSection: {
		margin: "10px 0.5rem 0 0.5rem"
	},
	evolutionSection: {
		margin: "-70px 0.5rem 0 0.5rem", 
		backgroundColor: "#fff",
		paddingBottom: "10px",
		width: "100%"
	},
	debatesSection: {
		margin: "-50px 0.5rem 0 0.5rem", 
		backgroundColor: "#fff", 
		width: "100%",
		paddingBottom: "10px"
	},
	optimizationList: {
		backgroundColor: "white",
		marginBottom: "60px"
	},
	infoBox: {
		margin: "0 1rem !important",
		marginBottom: "1rem !important",
		maxWidth: "800px"
	},
	infoBoxTitle: {
		margin: "0 !important"
	},
	infoBoxLink: {
		textDecoration: "none !important",
		color: "rgb(1, 67, 97) !important",
		fontWeight: "bold !important",
		paddingLeft: "5px",
		cursor: "pointer",
		"&:hover":{
			opacity: "0.8 !important"
		}
	},
	coverageInfo: {
		paddingLeft: "0.5rem",
		marginTop: "1.5rem"
	}
}));

export const Dashboard = () => {
	const [dateRange, setDateRange] = useState();
	const classes = useStyles();
	const settingsContext = useSettingsContext();
	const redirectTo = useRedirect();
	const translate = useTranslate();
	const applicationId = settingsContext?.settings?.id;

	return (
		applicationId ?
			<>
				<div className={classes.header}>
					<DashboardDatePicker onDateRangeChange={setDateRange} />
					<Button onClick={() => redirectTo("/groups/create")} className={`${classes.headerButton} ${classes.primaryButton}`} label={translate("pos.dashboard.create_debate")} style={{ marginRight: "10px" }} />
					<Button onClick={() => redirectTo("/groups")} className={classes.headerButton} label={translate("pos.dashboard.give_me_ideas")} />
				</div>
				<DashboardTitle title={translate("pos.dashboard.stats")} icon={<EqualizerIcon />} style={{marginTop: "20px", backgroundColor: "#fff", width: "100%", paddingBottom: "1.5rem"}} />
				<FormSectionTitle title={translate("pos.dashboard.results")} className={classes.resultsSection} variant="lowercase" />
				<MetabaseDashboard 
					appId={applicationId} 
					dashboardId={18}
					paramsObject={{
						"application_id": [
							applicationId
						],
						"date": dateRange
					}}
					bordered={false}
					titled={false}
					height={500}
					width={1400}
				/>
				<FormSectionTitle title={translate("pos.dashboard.evolutions")} className={classes.evolutionSection} icon={<TrendingUpIcon />} variant="lowercase"/>
				<MetabaseDashboard 
					appId={applicationId} 
					dashboardId={20}
					paramsObject={{
						"application_id": [
							applicationId
						],
						"date": dateRange
					}}
					bordered={false}
					titled={false}
					height={500}
					width={1200}
				/>
				<FormSectionTitle title={translate("pos.dashboard.popular_debates")} icon={<WhatshotIcon />} className={classes.debatesSection} variant="lowercase"/>
				{ dateRange && <DashboardDebates startDate={dateRange.split("~")[0]} endDate={dateRange.split("~")[1]} /> }
				<DashboardTitle title={translate("pos.dashboard.coverage")} icon={<BookmarksIcon />} />
				<div className={classes.coverageInfo}>{translate("pos.dashboard.coverage_info")}</div>
				<MetabaseDashboard 
					appId={applicationId} 
					dashboardId={17}
					paramsObject={{
						"application_id": [
							applicationId
						]
					}}
					bordered={false}
					titled={false}
					height={350}
					width={1200}
					style={{ marginTop: "20px" }}
				/>
				<DashboardTitle title={translate("pos.dashboard.optimization")} icon={<AutoFixHighIcon />} style={{ marginTop: "-55px", backgroundColor: "#fff", width: "100%", paddingBottom: "1.5rem" }}/>
				<div className={classes.optimizationList}>
					<Alert severity="info" className={classes.infoBox} icon={<Filter1Icon />}>
						<AlertTitle className={classes.infoBoxTitle}>
							{translate("pos.dashboard.optimization_login")}:
							<a className={classes.infoBoxLink} href={`${translate("pos.doc_url")}faq/registration`} rel="noreferrer noopenner" target="_blank">{translate("pos.main.doc_post")}</a>
						</AlertTitle>
					</Alert>
					<Alert severity="info" className={classes.infoBox} icon={<Filter2Icon />}>
						<AlertTitle className={classes.infoBoxTitle}>
							{translate("pos.dashboard.optimization_widget")}:
							<a className={classes.infoBoxLink} href={`${translate("pos.doc_url")}installation/homepage`} rel="noreferrer noopenner" target="_blank">{translate("pos.main.doc_post")}</a>
						</AlertTitle>
					</Alert>
					<Alert severity="info" className={classes.infoBox} icon={<Filter3Icon />}>
						<AlertTitle className={classes.infoBoxTitle}>
							{translate("pos.dashboard.optimization_argument_share")}:
							<a className={classes.infoBoxLink} href={`${translate("pos.doc_url")}faq/share`} rel="noreferrer noopenner" target="_blank">{translate("pos.main.doc_post")}</a>
						</AlertTitle>
					</Alert>
					<Alert severity="info" className={classes.infoBox} icon={<Filter4Icon />}>
						<AlertTitle className={classes.infoBoxTitle}>
							{translate("pos.dashboard.optimization_debate_share")}:
							<a className={classes.infoBoxLink} href={`${translate("pos.doc_url")}configuration/social`} rel="noreferrer noopenner" target="_blank">{translate("pos.main.doc_post")}</a>
						</AlertTitle>
					</Alert>
					<Alert severity="info" className={classes.infoBox} icon={<Filter5Icon />}>
						<AlertTitle className={classes.infoBoxTitle}>
							{translate("pos.dashboard.optimization_premium")}:
							<a className={classes.infoBoxLink} href={`${translate("pos.doc_url")}faq/premium`} rel="noreferrer noopenner" target="_blank">{translate("pos.main.doc_post")}</a>
						</AlertTitle>
					</Alert>
				</div>
			</>
		:
			<Loading />
	);
};
