import React from 'react';
import { Drawer, Stack, Typography } from '@mui/material';
import {
    BooleanField,
    Datagrid,
    DateField,
    FunctionField,
    Link,
    List,
    NumberField,
    TextField,
    UrlField,
    useRecordContext,
    useTranslate
} from 'react-admin';
import ArgumentEdit from '../../debate/arguments/ArgumentEdit';
import rowStyle from '../../debate/arguments/rowStyle';
import CustomPagination from '../../utils/CustomPagination';
import ModerationBulkActionButtons from '../../utils/ModerationBulkActionsButtons';
import ModerableContentFilter from './ModerableContentFilter';
import { useCallback } from 'react';
import { useNavigate, matchPath, matchRoutes } from 'react-router-dom';
import AuthorField from '../AuthorField';
import { providerIcons } from '../utils/providerIcons';
import { ModerableSource } from '../moderable_sources/moderableSource';

function SourceField({ label }: { label: string }) {
    const record = useRecordContext();

    if (!record?.post) {
        return null;
    }

    return (
        <Link
            to={record?.post.url}
            target="_blank"
            sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: 200
            }}
            title={record?.post.title || record?.post.body || '[Untitled]'}
        >
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="stretch"
                gap={1}
            >
                <img
                    src={
                        providerIcons[
                            record?.provider as ModerableSource['provider']
                        ]
                    }
                    style={{ maxWidth: '25px' }}
                    alt=""
                />
                <Typography
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '400px'
                    }}
                >
                    {record?.post.title || record?.post.body || '[Untitled]'}
                </Typography>
            </Stack>
        </Link>
    );
}

export const ModerableContentsList = () => {
    const translate = useTranslate();
    const navigate = useNavigate();
    const handleClose = useCallback(() => {
        navigate(`/${window.location.pathname.split('/')[1]}`);
    }, [navigate, window.location.pathname]);
    const routes = [{ path: '/moderable_contents/:id' }];
    const [{ route }] = matchRoutes(routes, window.location) || [
        { path: '/messages' }
    ];
    const match = route && matchPath(route.path, window.location.pathname);

    return (
        <div id="social-networks-root">
            <header style={{ marginBottom: '20px' }}>
                <h1>
                    {translate(
                        'pos.social_networks.moderable_contents.section_title'
                    )}
                </h1>
                <p>
                    {translate(
                        'pos.social_networks.moderable_contents.section_subtitle'
                    )}
                </p>
            </header>

            <List
                resource="moderable_contents"
                title="resources.moderable_contents.section_title"
                filters={<ModerableContentFilter />}
                sort={{ field: 'created_at', order: 'DESC' }}
                pagination={<CustomPagination />}
                sx={{ marginRight: '6px' }}
            >
                <Datagrid
                    rowSx={rowStyle}
                    optimized
                    bulkActionButtons={
                        <ModerationBulkActionButtons
                            resourceName="moderable_contents"
                            getElementId={undefined}
                        />
                    }
                >
                    <TextField source="id" label="ID" />

                    <AuthorField label="Author" />

                    <TextField
                        source="content"
                        label=""
                        sx={{ display: 'block', width: 400 }}
                    />

                    <SourceField label="Source" />

                    <BooleanField
                        source="parent_id"
                        looseValue
                        label={translate('resources.messages.fields.is_reply')}
                    />

                    <NumberField
                        source="moderation_entry.moderation_score"
                        label={translate(
                            'resources.messages.fields.moderation_score'
                        )}
                        transform={(v) => Math.floor(100 - v) / 100}
                        options={{ style: 'percent' }}
                    />
                    <DateField
                        source="published_at"
                        label={translate(
                            'resources.moderable_contents.fields.published_at'
                        )}
                        showTime
                    />

                    <DateField
                        source="created_at"
                        label={translate(
                            'resources.moderable_contents.fields.created_at'
                        )}
                        showTime
                    />
                    <FunctionField
                        label={translate(
                            'resources.moderable_contents.fields.status'
                        )}
                        render={(record: Record<string, any>) =>
                            record?.moderation_entry?.status === 'rejected'
                                ? translate(
                                      'resources.messages.status.rejected'
                                  )
                                : record?.moderation_entry?.status ===
                                  'accepted'
                                ? translate(
                                      'resources.messages.status.accepted'
                                  )
                                : translate('resources.messages.status.pending')
                        }
                    />
                </Datagrid>
            </List>
            <Drawer
                open={!!match}
                anchor="right"
                onClose={handleClose}
                sx={{ zIndex: 100 }}
            >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {!!match && (
                    <ArgumentEdit id={match.params.id} onCancel={handleClose} />
                )}
            </Drawer>
        </div>
    );
};
