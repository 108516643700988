import React, { useEffect } from 'react';
import { DashboardBox } from '../dashboard/DashboardBox';
import { History } from '@mui/icons-material';
import { LineChart } from '@mui/x-charts';
import { useQuery } from '@tanstack/react-query';
import httpClient from '../../httpClient';
import { useTranslate } from 'react-admin';

export function ModerableContentTimelineDashboardBox({
    providers,
    fromDate,
    toDate
}: {
    providers: string[];
    fromDate: string;
    toDate: string;
}) {
    const { data, isLoading } = useQuery<
        Record<string, Record<string, number>>
    >({
        queryKey: ['comments_history', providers, fromDate, toDate],
        queryFn: async () => {
            const url = new URL(
                process.env.REACT_APP_SOCIAL_MODERATOR_URL +
                    `/statistics/comments_history`
            );

            url.searchParams.append('filter', 'day');
            url.searchParams.append('from_date', fromDate);
            url.searchParams.append('to_date', toDate);

            if (providers.length > 0) {
                url.searchParams.append('providers', providers.join(','));
            }

            const response = await httpClient(url);

            return response && response.json;
        },
        staleTime: 5 * 60 * 1000
    });

    const translate = useTranslate();

    return (
        <DashboardBox
            title={translate('pos.social_networks.dashboard.metrics.comments')}
            icon={History}
            borderRadius="sm"
        >
            {data && Object.keys(data).length > 0 && (
                <LineChart
                    height={300}
                    series={Object.entries(data).map(([provider, d]) => ({
                        data: Object.values(d),
                        label: provider
                    }))}
                    xAxis={[
                        {
                            scaleType: 'point',
                            data: Object.keys(Object.values(data)[0])
                        }
                    ]}
                    grid={{ vertical: true, horizontal: true }}
                />
            )}
        </DashboardBox>
    );
}
