import { formatDate, formatOrgane, formatLabel, formatChambre, capitalizeFirstLetter } from "../formatters";
import { BooleanField } from "../components/BooleanField";
import { ReunionIcon } from "@logora/parliament.icons.regular_icons";
import { Label } from "@logora/parliament.ui.label"

const formatMotif = (field, item) => {
    return capitalizeFirstLetter(item.resumeOdj?.join('\n') || item.convocationOdj?.join('\n') || item.objet || item.compteRenduRef?.quantiemesJournee)
}

const formatCompteRendu = (field, item) => {
    return <BooleanField condition={item.compteRenduRefUid !== null} />
}

const formatBooleen = (field, item) => {
    return <BooleanField condition={item[field]} />
}

export const formatEtat = (field, item) => {
    if(!item.etat) return null

    let colorVariants = {
        "success": "Confirmé",
        "info": "Eventuel",
        "cancel": ["Annulé", "Supprimé"]
    }
    return <Label text={item.etat.charAt(0).toUpperCase() + item.etat.slice(1)} variantsObject={colorVariants} colorType={item.etat} />;
}

const reunionsFields = {
    chambre: { label: 'Chambre', field: 'chambre', format: formatChambre },
    'timestampDebut': { label: 'Date', field: 'timestampDebut', format: formatDate, sort: "timestampDebut", defaultSort: true },
    'organeReunionRef': { label: 'Organe', field: 'organeReunionRef', include: "organeReunionRef", format: formatOrgane },
    'motif': { label: 'Motif de la réunion', field: 'motif', format: formatMotif },
    'nombreParticipants': { label: 'Nombre de participants', field: 'nombreParticipants' },
    'compteRenduRefUid': { label: 'Compte-rendu', field: 'compteRenduRefUid', sort: 'compteRenduRefUid', include: "compteRenduRef", format: formatCompteRendu },
    'etat': { label: 'État', field: 'etat', sort: 'etat', format: formatEtat },
    'captationVideo': { label: 'Vidéo', field: 'captationVideo', sort: 'captationVideo', format: formatBooleen },
}

const reunionsFilters = [
    {
        field: "chambre", label: "Chambre", type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: "AN", label: 'Assemblée' },
            { value: "SN", label: 'Sénat' },
        ]
    },
    {
        field: "etat", label: 'État', type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: "Confirmé", label: 'Confirmé' },
            { value: "Annulé", label: 'Annulé' },
            { value: "Supprimé", label: 'Supprimé' },
            { value: "Eventuel", label: 'Éventuel' }
        ]
    },
    {
        field: "compteRenduDisponible", label: 'Compte-rendu', type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: true, label: 'Disponible' },
            { value: false, label: 'Non disponible' }
        ]
    },
    {
        field: "captationVideo", label: 'Vidéo', type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: true, label: 'Oui' },
            { value: false, label: 'Non' }
        ]
    }
];

export const reunionData = {
    key: "reunions",
    name: "Réunions",
    includes: ["compteRenduRef"],
    filters: reunionsFilters,
    filterMapping: { "organeRefUid": "organeReunionRefUid" },
    fields: reunionsFields,
    icon: <ReunionIcon width={20} height={20} />
}