import React, { useMemo, useEffect } from 'react';
import base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import HmacSHA256 from 'crypto-js/hmac-sha256';

const expirationDate = Math.round(Date.now() / 1000) + (60 * 60)

export const MetabaseDashboard = React.memo(({ dashboardId, paramsObject, bordered, titled, style, height, width }) => {
    const metabaseUrl = "https://data.logora.com/embed/dashboard/";
    const secret = "44b7d74f27935f470815c4266ed17146221a7756d81c79b54e2f39fb42513acb";

    const base64url = (source) => {
        let encodedSource = base64.stringify(source);
        encodedSource = encodedSource.replace(/=+$/, '');
        encodedSource = encodedSource.replace(/\+/g, '-');
        encodedSource = encodedSource.replace(/\//g, '_');
        return encodedSource;
    }

    const iframeLink = useMemo(() => {
        const header = {
            "alg": "HS256",
            "typ": "JWT"
        }

        const data = {
            resource: { 
                dashboard: dashboardId
            },
            params: paramsObject,
            exp: expirationDate
        }

        let stringifiedHeader = Utf8.parse(JSON.stringify(header));
        let encodedHeader = base64url(stringifiedHeader);
        let stringifiedData = Utf8.parse(JSON.stringify(data));
        let encodedData = base64url(stringifiedData);
        let token = encodedHeader + "." + encodedData;
        if (!secret) return token;
        let signature = HmacSHA256(token, secret);
        signature = base64url(signature);
        return `${metabaseUrl}${encodedHeader}.${encodedData}.${signature}#bordered=${bordered}&titled=${titled}`;
    })

    return (
        <div style={style}>
            <iframe
                src={iframeLink}
                style={{ border: 0 }}
                height={height}
                width={width}
                allowtransparency="true"
                title={`Metabase dashboard #${dashboardId}`}
                loading={"lazy"}
            />
        </div>
    )
})
