import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDataProvider } from 'react-admin';
import { Loader } from '@logora/debate.progress.loader';
import { InterventionBox } from '@logora/parliament.debate.intervention_box';
import sanitizeHtml from 'sanitize-html'
import styles from './DebatShow.module.scss';
import { useNavigate } from 'react-router-dom';

const CODE_STYLES = {
    TITLE: ['Titre', 'Présidence'],
    SUBTITLE: ['Sous-tit_info', 'Sous-tit_p_cap', 'Sous-titre bdc']
};

const sanitize = (html) => {
    const sanitizeOptions = {
        allowedTags: ['i', 'sup', 'br']
    };

    let finalHtml = html.replace("<italique>", "<i>")
    finalHtml = finalHtml.replace("</italique>", "</i>")
    finalHtml = finalHtml.replace("<exposant>", "<sup>")
    finalHtml = finalHtml.replace("</exposant>", "</sup>")
    const sanitizedHtml = sanitizeHtml(finalHtml, sanitizeOptions)

    return { __html: sanitizedHtml }
}

export const DebatDetails = ({ resource }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [interventions, setInterventions] = useState([])
    const dataProvider = useDataProvider()

    useEffect(() => {
        if (resource) {
            fetchInterventions();
        }
    }, [resource]);

    const fetchInterventions = useCallback(async () => {
        try {
            const response = await dataProvider.getList('interventions', {
                filter: { debatRefUid: resource.uid },
                include: ['acteurRef.groupeParlementaire'],
                sort: { field: 'ordreAbsoluSeance', order: 'asc' },
                pagination: { perPage: 1000 }
            });
            const interventions = response.data;
            if (interventions) {
                setInterventions(interventions);
            }
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }, [dataProvider]);

    const displayIntervention = (intervention) => {
        if (!intervention.texte) return null;

        const isTitle = CODE_STYLES.TITLE.includes(intervention.codeStyle);
        const isSubtitle = CODE_STYLES.SUBTITLE.includes(
            intervention.codeStyle
        );

        if (isTitle || isSubtitle) {
            return (
                <div
                    id={
                        isTitle
                            ? intervention.dossierRefUid || intervention.id
                            : intervention.id
                    }
                    className={
                        isTitle
                            ? styles.interventionTitle
                            : styles.interventionSubtitle
                    }
                    dangerouslySetInnerHTML={sanitize(intervention.texte)}
                />
            );
        } else {
            return (
                <InterventionBox
                    key={intervention.ordreAbsoluSeance}
                    intervention={intervention}
                />
            );
        }
    };

    const summary = useMemo(
        () =>
            interventions.reduce((summary, intervention) => {
                if (intervention.codeStyle === 'Titre') {
                    summary.push({
                        id: intervention.dossierRefUid || intervention.id,
                        title: intervention.texte,
                        subtitles: []
                    });
                } else if (
                    CODE_STYLES.SUBTITLE.includes(intervention.codeStyle)
                ) {
                    summary[summary.length - 1].subtitles.push({
                        id: intervention.id,
                        title: intervention.texte
                    });
                }

                return summary;
            }, []),
        [interventions, CODE_STYLES]
    );

    return (
        <>
            {interventions?.length > 0 ? (
                <div className={styles.container}>
                    <DebatSummary
                        reunionRefUid={resource.reunionRefUid}
                        summary={summary}
                    />
                    <div className={styles.corps}>
                        {interventions.map(displayIntervention)}
                    </div>
                </div>
            ) : (
                <>
                    {isLoading ?
                        <div className={styles.loader}>
                            <Loader />
                        </div>
                        :

                        <div>Aucune intervention dans le débat</div>
                    }
                </>
            )}
        </>
    );
};

export const DebatSummary = ({ reunionRefUid, summary }) => {
    const navigate = useNavigate();

    const handleClick = (id) => {
        navigate(`/parliament/reunions/${reunionRefUid}#${id}`);

        document.getElementById(id).scrollIntoView({
            behavior: 'smooth'
        });
    };

    return (
        <ol className={styles.summary}>
            {summary.map(({ id, title, subtitles }) => (
                <li>
                    <button
                        onClick={() => handleClick(id)}
                        className={styles.summaryTitle}
                        dangerouslySetInnerHTML={sanitize(title)}
                    />

                    <ol>
                        {subtitles.map((subtitle) => (
                            <li>
                                <button
                                    onClick={() => handleClick(subtitle.id)}
                                    className={styles.summarySubtitle}
                                    dangerouslySetInnerHTML={sanitize(subtitle.title)}
                                />
                            </li>
                        ))}
                    </ol>
                </li>
            ))}
        </ol>
    );
};
