import React, { useState } from 'react';
import { ModerableSource } from './moderableSource';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import styles from './ModerableSourceCard.module.scss';
import { Delete, WatchLater } from '@mui/icons-material';
import { providerIcons } from '../utils/providerIcons';
import {
    Confirm,
    useDelete,
    useLocale,
    useNotify,
    useTranslate
} from 'react-admin';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export function ModerableSourceCard({
    moderableSource
}: {
    moderableSource: ModerableSource;
}) {
    const queryClient = useQueryClient();
    const notify = useNotify();
    const locale = useLocale();
    const translate = useTranslate();

    const [removeModerableSource, { isPending: isDeleting }] = useDelete(
        'moderable_sources',
        {
            id: moderableSource.id
        },
        {
            onSuccess: () => {
                Promise.all([
                    queryClient.invalidateQueries({
                        queryKey: ['moderable_sources']
                    }),

                    queryClient.invalidateQueries({
                        queryKey: ['posts']
                    })
                ]);

                notify(`Successfully removed ${moderableSource.name}.`, {
                    type: 'success'
                });
            },

            onError: () =>
                notify('Something went wrong, please try again later.', {
                    type: 'error'
                })
        }
    );

    const [isConfirmOpen, setConfirmOpen] = useState(false);

    const handleCloseConfirm = () => {
        setConfirmOpen(false);
    };

    const handleConfirm = () => {
        setConfirmOpen(false);
        removeModerableSource();
    };

    const handleOpenConfirm = () => {
        setConfirmOpen(true);
    };

    return (
        <>
            <Box className={styles.card} data-status={moderableSource.status}>
                <Stack direction="row" gap={2}>
                    <img src={providerIcons[moderableSource.provider]} alt="" />

                    <Stack>
                        <h3>
                            <a href={moderableSource.url} target="_blank">
                                {moderableSource.name}
                            </a>
                        </h3>

                        {moderableSource.status === 'active' && (
                            <Stack
                                direction="row"
                                gap={0.75}
                                alignItems="center"
                            >
                                <WatchLater fontSize="small" />

                                <Typography fontSize="0.8rem">
                                    {translate(
                                        'pos.social_networks.dashboard.next_recovery'
                                    )}
                                    <b>
                                        {' '}
                                        <time
                                            dateTime={
                                                moderableSource.next_recovery_at
                                            }
                                            title={dayjs(
                                                moderableSource.next_recovery_at
                                            ).format('HH:mm:ss')}
                                        >
                                            {dayjs(
                                                moderableSource.next_recovery_at
                                            )
                                                .locale(locale)
                                                .fromNow()}
                                        </time>
                                    </b>
                                </Typography>
                            </Stack>
                        )}

                        {moderableSource.status === 'pending' && (
                            <Stack
                                direction="row"
                                gap={0.75}
                                alignItems="center"
                            >
                                <CircularProgress size={20} />

                                <p>
                                    {translate(
                                        'resources.moderable_sources.status.pending'
                                    )}
                                </p>
                            </Stack>
                        )}

                        {moderableSource.status === 'error' && (
                            <Stack
                                direction="row"
                                gap={0.75}
                                alignItems="center"
                            >
                                <p>
                                    {translate(
                                        'resources.moderable_sources.status.error'
                                    )}
                                </p>
                            </Stack>
                        )}
                    </Stack>
                </Stack>

                {isDeleting ? (
                    <CircularProgress size={24} />
                ) : (
                    <button onClick={handleOpenConfirm}>
                        <Delete sx={{ color: 'var(--clr-neutral-0)' }} />
                    </button>
                )}
            </Box>

            <Confirm
                isOpen={isConfirmOpen}
                title="Remove this source ?"
                content="This will remove all the posts and comments from this source."
                onConfirm={handleConfirm}
                onClose={handleCloseConfirm}
            />
        </>
    );
}
