import { formatDate, formatLabel, formatNumber, formatChambre, formatNumeroFromUid, formatTexteLegislatif, formatActeur } from "../formatters";
import { AmendmentIcon } from "@logora/parliament.icons.regular_icons";

const amendementsFields = {
    chambre: { label: 'Chambre', field: 'chambre', format: formatChambre },
    'dateDepot': { label: "Date de dépôt", field: "dateDepot", format: formatDate, sort: "dateDepot", defaultSort: true },
    'acteurRef': { label: "Auteur", field: "acteurRef", include: "acteurRef.groupeParlementaire", format: formatActeur },
    'numeroLong': { label: "Numéro", field: "uid", format: formatNumeroFromUid },
    'texteLegislatifRef': { label: "Texte", field: "texteLegislatifRef", include: "texteLegislatifRef.dossierRef", format: formatTexteLegislatif },
    'divisionTitre': { label: "Article", field: "divisionTitre" },
    'nombreCoSignataires': { label: "Nombre de co-signataires", field: "nombreCoSignataires", format: formatNumber },
    'sortAmendement': { label: "Sort", field: "sortAmendement", format: formatLabel }
}

const amendementsFilters = [
    {
        field: "chambre", label: "Chambre", type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: "AN", label: 'Assemblée' },
            { value: "SN", label: 'Sénat' },
        ]
    },
    {
        field: "sortAmendement", label: 'Sort', type: 'select', options: [
            { value: null, label: 'Tous' },
            { value: "Adopté", label: 'Adopté' },
            { value: "Rejeté", label: 'Rejeté' },
            { value: "En traitement", label: 'En traitement' },
            { value: "A discuter", label: 'À discuter' },
            { value: "Retiré", label: 'Retiré' },
            { value: "Irrecevable", label: 'Irrecevable' },
            { value: "Irrecevable 40", label: 'Irrecevable 40' },
            { value: "Tombé", label: 'Tombé' }
        ]
    },
    { field: "numeroLong", label: "Numéro", type: "text" }
];

export const amendementData = {
    key: "amendements",
    name: "Amendements",
    filters: amendementsFilters,
    filterMapping: { "organeRefUid": "acteurRef_groupeParlementaireUid" },
    fields: amendementsFields,
    icon: <AmendmentIcon height={20} width={20} />,
    rowRedirect: (item) => `parliament/recherche/amendements/${item.uid}`
}