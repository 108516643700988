import React, { useState, useEffect, useCallback, cloneElement } from 'react';
import { useDataProvider, useAuthenticated, useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Loader } from '@logora/debate.progress.loader';
import styles from "./Show.module.scss"

export const Show = ({ resource, includes = [], backText, backLink, children }) => {
    const { id } = useParams()
    const dataProvider = useDataProvider()
    const redirectTo = useRedirect()
    const [loading, setLoading] = useState(true)
    const [record, setRecord] = useState(null)

    useAuthenticated()

    const fetchRecord = useCallback(async () => {
        try {
            const response = await dataProvider.getOne(resource, {
                id: id,
                include: includes
            });
            const record = response.data;
            if (record) {
                setRecord(record)
                setLoading(false)
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }, [dataProvider]);

    useEffect(() => {
        if (resource) {
            fetchRecord();
        }
    }, []);

    if (!children) return null

    return (
        <div className={styles.container}>
            {backText && backLink &&
                <div className={styles.backLink} onClick={() => redirectTo(backLink)}>
                    {backText}
                </div>
            }
            {loading || !record ?
                <div className={styles.loader}>
                    <Loader />
                </div>
                :
                <>
                    {cloneElement(children, { resource: record, resourceId: id })}
                </>
            }
        </div>
    );
};