import React from 'react';
import { ButtonBase } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import styles from './ExposeMotifs.module.scss';

export const ExposeMotifs = ({ document, text }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Exposé des motifs</div>

        {document?.pdfUrl &&
          <ButtonBase href={document?.pdfUrl} className={styles.originalTextButton} target='_blank' >
            <span>Consulter texte original</span>
            <OpenInNew fontSize='small' />
          </ButtonBase>
        }
      </div>

      <div className={styles.content}>
        {text ?
          <div className={styles.text}>
            {text}
          </div>
          :
          <i>Pas d'exposé des motifs</i>
        }
      </div>
    </div>
  );
}
