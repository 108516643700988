import React from 'react';
import { useRedirect } from 'react-admin';
import { Show } from '../components/show/Show';
import { Box } from '@logora/parliament.ui.box';
import { Label } from '@logora/parliament.ui.label';
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import { ChamberLabel } from '@logora/parliament.parliament_chamber.chamber_label';
import { formatDate } from '../formatters'
import styles from './DocumentShow.module.scss'

const DocumentDetails = ({ resource }) => {
    const redirectTo = useRedirect();

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <>
            <div className={styles.header}>
                <ChamberLabel chamber={resource.chambre} />
                <Label text={resource.classeLibelle} />
            </div>
            <div className={styles.title}>
                {capitalizeFirstLetter(resource.titrePrincipalCourt)}
            </div>
            <div className={styles.presentation}>
                <div className={styles.box}>
                    <Box title="Auteur" className={styles.boxContainer}>
                        <MemberBox
                            firstName={resource.auteurPrincipal?.prenom}
                            lastName={resource.auteurPrincipal?.nom}
                            imageUrl={resource.auteurPrincipal?.urlImage}
                            parliamentGroup={{
                                groupName: resource.auteurPrincipal?.groupeParlementaire?.libelle,
                                color: resource.auteurPrincipal?.groupeParlementaire?.couleurAssociee
                            }}
                            onClick={() => redirectTo(`/parliament/acteurs/${resource.auteurPrincipalUid}`)}
                        />
                    </Box>
                </div>
                {resource.organeRef &&
                    <div className={styles.box}>
                        <Box title="Commission" className={styles.boxContainer}>
                            <div className={styles.link} onClick={() => redirectTo(`/parliament/organes/${resource.organeRefUid}`)}>
                                {resource.organeRef?.libelle}
                            </div>
                        </Box>
                    </div>
                }
                <div className={styles.box}>
                    <Box title="Date de création" className={styles.boxContainer}>
                        <div>{formatDate("dateCreation", resource)}</div>
                    </Box>
                </div>
            </div>
            <div className={styles.corps}>
                {resource.pdfUrl ?
                    <>
                        <object
                            type="application/pdf"
                            data={`${resource.pdfUrl}#zoom=100`}
                            width="1100px"
                            height="900px"
                        >
                            <div className={styles.empty}>Le document n'est pas disponible</div>
                        </object>
                    </>
                    :
                    <div className={styles.empty}>Le document n'est pas disponible</div>
                }
            </div>
        </>
    );
};

export const DocumentShow = () => {
    return (
        <Show
            resource={"documents"}
            includes={["auteurPrincipal.groupeParlementaire", "organeRef"]}
            backLink={"/parliament/recherche/documents"}
            backText={"Voir tous les documents"}
        >
            <DocumentDetails />
        </Show >
    );
};
