import React from 'react';
import { AmendmentBox } from '@logora/parliament.amendment.amendment_box'
import { List } from '../../../parliament/search/List';
import { amendementData } from '../../amendements';
import styles from './AmendementList.module.scss';
import cloneDeep from 'lodash/cloneDeep';

export const AmendementList = ({ document, subdivisionRefHash = "" }) => {
  const getAmendementDataset = () => {
    amendementData.filters = amendementData.filters.filter((f) => f.field !== "chambre")
    const amendementsFilters = amendementData.filters.concat([
      { field: "texteLegislatifRefUid", hidden: true, defaultValue: document?.uid, type: 'text' },
      { field: "subdivisionRefHash", hidden: true, defaultValue: subdivisionRefHash, type: 'text' },
    ])

    return Object.assign(cloneDeep(amendementData), { filters: amendementsFilters })
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          Amendements
        </div>
      </div>
      <div className={styles.contentContainer}>
        {document?.uid &&
          <List
            dataset={getAmendementDataset()}
            globalFilters={[]}
            display={true}
            key={"amendements"}
            role="tabpanel"
            tabIndex={0}
            showSearch={true}
            Children={AmendmentBox}
          />
        }
      </div>
    </div>
  );
};
