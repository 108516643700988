import { useCallback } from 'react';
import { useDataProvider } from 'react-admin';

export const useCreateAlert = (props) => {
    const dataProvider = useDataProvider();

    const createAlert = useCallback(async () => {
        try {
            const response = await dataProvider.create('alerts', { data: props });
            return response;
        } catch (error) {
            console.error('Error creating alert:', error);
            throw error;
        }
    }, [dataProvider, props]);

    return createAlert;
};
