import React from 'react';
import {
    TextField,
    Checkbox,
    FormControlLabel,
    Box,
    MenuItem
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';

export const FilterBar = ({ filters, onFilterChange, title = null }) => {
    const [_, setSearchParams] = useSearchParams();
    const handleFilterChange = (filter, newValue) => {
        onFilterChange(
            filter,
            filter.format ? filter.format(newValue) : newValue
        );
        setSearchParams((params) => {
            newValue !== null
                ? params.set(filter.field, newValue)
                : params.delete(filter.field);

            return params;
        });
    };

    const renderFilterField = (filter) => {
        switch (filter.type) {
            case 'date':
                return (
                    <TextField
                        label={filter.label}
                        type="date"
                        value={filter.value}
                        defaultValue={filter.defaultValue}
                        onChange={(e) =>
                            handleFilterChange(filter, e.target.value)
                        }
                        size="small"
                        sx={{ zIndex: 0 }}
                    />
                );
            case 'boolean':
                return (
                    <FormControlLabel
                        sx={{ zIndex: 0 }}
                        control={
                            <Checkbox
                                defaultChecked={filter.defaultValue}
                                onChange={(e) =>
                                    handleFilterChange(filter, e.target.checked)
                                }
                            />
                        }
                        label={filter.label}
                    />
                );
            case 'select':
                return (
                    <TextField
                        label={filter.label}
                        value={filter.value}
                        defaultValue={filter.defaultValue}
                        select
                        SelectProps={{ autoWidth: true }}
                        onChange={(e) =>
                            handleFilterChange(filter, e.target.value)
                        }
                        size="small"
                        sx={{ minWidth: '10em', zIndex: 0 }}
                    >
                        {filter.options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            case 'text':
                return (
                    <TextField
                        sx={{ zIndex: 0 }}
                        label={filter.label}
                        defaultValue={filter.defaultValue}
                        onKeyUp={(e) => {
                            if (e.key === 'Enter') {
                                handleFilterChange(
                                    filter,
                                    e.target.value === ''
                                        ? null
                                        : e.target.value
                                );
                            }
                        }}
                        size="small"
                    />
                );
        }
    };

    const visibleFilters = filters?.filter((f) => f.hidden !== true);

    return (
        <>
            {visibleFilters?.length > 0 && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: '1em 0em'
                    }}
                >
                    { title && <div>{title}</div>}
                    <Box
                        sx={{
                            display: 'flex',
                            flexFlow: 'row wrap',
                            gap: '1em'
                        }}
                    >
                        {visibleFilters.map((filter) => {
                            return (
                                <Box key={`${filter.field}`} display={'flex'}>
                                    {renderFilterField(filter)}
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            )}
        </>
    );
};
