import instagram from '../assets/instagram.svg';
import facebook from '../assets/facebook.svg';
import youtube from '../assets/youtube.svg';
import { ModerableSource } from '../moderable_sources/moderableSource';

export const providerIcons: Record<ModerableSource['provider'], string> = {
    youtube,
    facebook,
    instagram
} as const;
