import { Box, Stack } from '@mui/material';
import React from 'react';
import { providerIcons } from '../utils/providerIcons';
import { ModerableSource } from '../moderable_sources/moderableSource';
import { OAuth2Button } from '@logora/debate.auth.oauth2_button';
import styles from './PlatformOAuth2Card.module.scss';
import { useTranslate } from 'react-admin';

export function PlatformOAuth2Card({
    platformInfos: {
        name,
        description,
        authUrl,
        clientId,
        scope,
        forceAuth = false,
        accessType
    }
}: {
    platformInfos: {
        name: ModerableSource['provider'];
        description: string;
        authUrl: string;
        clientId: string;
        scope: string;
        forceAuth?: boolean;
        accessType?: string;
    };
}) {
    const translate = useTranslate();

    return (
        <Box
            sx={{
                padding: '20px',
                border: '1px solid var(--clr-neutral-800)',
                borderRadius: '6px'
            }}
        >
            <Stack>
                <img
                    className={styles.platformIcon}
                    src={providerIcons[name]}
                    alt=""
                />
                <h3 className={styles.platformName}>{name}</h3>
                <p className={styles.platformDescription}>
                    {translate(
                        `pos.social_networks.add_source.platforms.${name}.description`
                    )}
                </p>

                <OAuth2Button
                    popup={false}
                    authDialogUrl={authUrl}
                    clientId={clientId}
                    scope={scope}
                    forceAuth={forceAuth}
                    responseType="code"
                    accessType={accessType}
                    redirectUri={process.env.REACT_APP_API_AUTH_CALLBACK}
                    className={`btn btn-outline ${styles.connectBtn}`}
                    state={`${window.location.origin}/integrations/${name}/callback`}
                >
                    {translate('pos.social_networks.actions.connect')}
                </OAuth2Button>
            </Stack>
        </Box>
    );
}
