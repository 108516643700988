import React from 'react';
import { useRedirect, Button } from 'react-admin';
import { Box } from '@mui/material';
import { Box as UiBox } from '@logora/parliament.ui.box';
import { Label } from '@logora/parliament.ui.label';
import { AmendmentIcon } from "@logora/parliament.icons.regular_icons";
import { MemberBox } from '@logora/parliament.parliament_member.member_box';
import { formatLabel } from '../formatters';
import styles from './AmendementShow.module.scss';

export const AmendementShow = ({ amendement }) => {
    const redirectTo = useRedirect();

    if (!amendement) { return; }

    return (
        <Box display="flex" flexDirection="column" padding={"2em"} gap="1em" width={"800px"}>
            <Box display="flex" alignItems="center" justifyContent={"left"}>
                <Label text="Amendement" leftIcon={<AmendmentIcon width={18} height={18} />} />
            </Box>
            <div className={styles.titre}>
                {`Amendement n°${amendement.numeroOrdreDepot}${amendement.texteLegislatifRef?.dossierRef ? ` — ${amendement.texteLegislatifRef?.dossierRef?.titre}` : ""}`}
            </div>
            {amendement.texteLegislatifRef?.dossierRefUid &&
                <Box display="flex">
                    <Button
                        className={styles.mainButton}
                        label="Voir le dossier"
                        onClick={() => redirectTo(`/parliament/dossiers/${amendement.texteLegislatifRef?.dossierRefUid}`)}
                    />
                </Box>
            }
            <Box display="flex" flexDirection="row" justifyContent="flex-start" gap="1em">
                <UiBox title='Auteur'>
                    <MemberBox
                        firstName={amendement.acteurRef?.prenom}
                        lastName={amendement.acteurRef?.nom}
                        imageUrl={amendement.acteurRef?.urlImage}
                        parliamentGroup={{
                            groupName: amendement.acteurRef?.groupeParlementaire?.libelleAbrege,
                            color: amendement.acteurRef?.groupeParlementaire?.couleurAssociee
                        }}
                        chamber={amendement.acteurRef?.chambre}
                        onClick={() => redirectTo(`/parliament/acteurs/${amendement.acteurRefUid}`)}
                    />
                </UiBox>
                <UiBox title="Date de dépôt">
                    <div>{new Date(amendement.dateDepot).toLocaleDateString()}</div>
                </UiBox>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-start" gap="1em">
                <UiBox title='Étape du texte'>
                    <div>{amendement.texteLegislatifRef?.dossierRef?.statut}</div>
                </UiBox>
                <UiBox title="Statut">
                    <div>{formatLabel("sortAmendement", amendement)}</div>
                </UiBox>
            </Box>
            {amendement.dispositif &&
                <UiBox title={`${amendement.divisionArticleDesignation} · ${amendement.alineaDesignation || ""}`}>
                    {amendement.dispositif}
                </UiBox>
            }
            {amendement.exposeSommaire &&
                <UiBox title={"Exposé des motifs"}>
                    {amendement.exposeSommaire}
                </UiBox>
            }
        </Box>
    );
};
