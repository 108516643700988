import React from 'react';
import { Post } from './post';
import { Box, Stack, Typography } from '@mui/material';
import { providerIcons } from '../utils/providerIcons';
import { Chat, Share, ThumbUp } from '@mui/icons-material';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import { useLocale } from 'react-admin';

dayjs.extend(relativeTime);

export function PostCard({ post, rank }: { post: Post; rank: number }) {
    const locale = useLocale();

    return (
        <a href={post.url} target="_blank" rel="noopener noreferrer">
            <Box
                sx={{
                    backgroundColor: 'var(--clr-neutral-900)',
                    borderRadius: '6px'
                }}
                padding="23px"
            >
                <Stack direction="row" gap={1} alignItems="center">
                    <img
                        src={providerIcons[post.moderable_source.provider]}
                        style={{ width: '24px', height: '24px' }}
                        alt=""
                    />
                    <h3>
                        <p>{post.moderable_source.name}</p>
                    </h3>

                    <Typography
                        sx={{
                            fontSize: '1rem',
                            fontWeight: '600',
                            color: 'var(--clr-neutral-0)',
                            marginLeft: 'auto'
                        }}
                    >
                        #{rank}
                    </Typography>
                </Stack>
                <p
                    style={{
                        marginTop: 20,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {post.body || post.title || '[No text content]'}
                </p>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ marginTop: '20px' }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={3}
                        color={'var(--clr-neutral-0)'}
                    >
                        {post.comments_count != null && (
                            <Stack direction="row" gap={1} alignItems="center">
                                <Chat height={20} width={20} />
                                <span>{post.comments_count}</span>
                            </Stack>
                        )}

                        {post.likes_count != null && (
                            <Stack direction="row" gap={1} alignItems="center">
                                <ThumbUp height={20} width={20} />
                                <span>{post.likes_count}</span>
                            </Stack>
                        )}

                        {post.shares_count != null && (
                            <Stack direction="row" gap={1} alignItems="center">
                                <Share height={20} width={20} />
                                <span>{post.shares_count}</span>
                            </Stack>
                        )}
                    </Stack>
                    {dayjs(post.published_at).locale(locale).fromNow()}
                </Stack>
            </Box>
        </a>
    );
}
