import React from 'react';
import { TimelineStep } from './TimelineStep';
import { ChamberLabel } from '@logora/parliament.parliament_chamber.chamber_label';
import styles from './TimelineMilestone.module.scss';
import cx from 'classnames';

export const TimelineMilestone = ({ milestone, selectedActeUid, currentActeUid, onChangeActe }) => {
  const { acteLegislatif, steps, title, } = milestone;
  const isSelected = acteLegislatif?.uid === selectedActeUid || steps?.some(s => s.acteLegislatif?.uid === selectedActeUid)

  const getDate = () => {
    const dateActe = acteLegislatif?.dateActe ? new Date(acteLegislatif.dateActe).toLocaleDateString() : null
    if (title === "Promulgation") {
      return dateActe ? `Texte promulgué le ${dateActe}` : "Sous réserve d'adoption du texte"
    }

    return dateActe
  }

  const date = getDate()

  const getNodeClass = () => {
    var now = new Date();

    if (currentActeUid === acteLegislatif?.uid || steps?.some(s => s.acteLegislatif?.uid === currentActeUid)) {
      return styles.nodeActive
    }

    if (steps?.length) {
      if (steps.every(s => new Date(s.acteLegislatif?.dateActe) < now)) {
        return styles.nodeComplete
      } else {
        return styles.nodePending
      }
    } else {
      if (!acteLegislatif || new Date(acteLegislatif?.dateActe) > now) {
        return styles.nodePending
      } else {
        return styles.nodeComplete
      }
    }
  }

  const onClickMilestone = () => {
    if(onChangeActe && acteLegislatif) {
      if(acteLegislatif.dateActe) {
        onChangeActe(acteLegislatif.uid)
      } else {
        onChangeActe(steps[0].acteLegislatif?.uid)
      }
    }
  }

  const onClickStep = (event, step) => {
    event.preventDefault()
    event.stopPropagation()

    if(onChangeActe && step.acteLegislatif) {
      onChangeActe(step.acteLegislatif?.uid)
    }
  }

  const nodeClass = getNodeClass()

  return (
    <div
      className={cx(styles.milestone, nodeClass, { [styles.selected]: isSelected })}
      onClick={onClickMilestone}
    >
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        {(acteLegislatif?.chambre || milestone.chambre) &&
          <ChamberLabel className={styles.subtitle} chamber={acteLegislatif?.chambre || milestone.chambre} />
        }
      </div>

      <div className={styles.nodeWrapper}>
        <div className={`${styles.node} ${nodeClass}`}>
          <button
            onClick={onClickMilestone}
            className={`${styles.button}`}
          />
        </div>
      </div>

      <div className={styles.steps} role="list">
        {date && <div className={styles.subtext}>{date}</div>}
        {steps.map((step, index) => (
          <TimelineStep
            key={index}
            selectedActeUid={selectedActeUid}
            currentActeUid={currentActeUid}
            onClickStep={(event) => onClickStep(event, step)}
            label={step.title}
            acteLegislatif={step.acteLegislatif}
          />
        ))}
      </div>
    </div>
  );
};