// in src/MyLayout.js
import React from 'react';
import { Layout } from 'react-admin';
import MyMenu from './Menu';
import MyAppBar from './AppBar';
import { MainError } from './MainError';

const MyLayout = ({ children }) => {
  return (
    <Layout menu={MyMenu} appBar={MyAppBar} error={MainError}>
      { children }
    </Layout>
  )
}

export default MyLayout;