import React, { useState } from 'react';
import { DashboardBox } from '../dashboard/DashboardBox';
import { Add, Public, Settings } from '@mui/icons-material';
import { useGetList, useTranslate } from 'react-admin';
import { Stack } from '@mui/material';
import { ModerableSourceCard } from './ModerableSourceCard';
import { CreateModerableSourceDialog } from './CreateModerableSourceDialog';
import styles from './ModerableSourceDashboardBox.module.scss';

export function ModerableSourceDashboardBox() {
    const translate = useTranslate();
    const { data: sources } = useGetList(
        'moderable_sources',
        {},
        { staleTime: Infinity }
    );
    const [isDialogOpen, setDialogOpen] = useState(false);

    return (
        <DashboardBox
            title={translate('pos.social_networks.dashboard.sources')}
            icon={Public}
        >
            <Stack height="100%" justifyContent="space-between" gap={1}>
                <Stack gap={1}>
                    {sources?.map((source) => (
                        <ModerableSourceCard
                            key={source.id}
                            moderableSource={source}
                        />
                    ))}
                </Stack>

                <button
                    className={`btn btn-primary ${styles.newSourceBtn}`}
                    onClick={() => setDialogOpen(true)}
                >
                    <Add />{' '}
                    {translate('pos.social_networks.actions.add_source')}
                </button>
            </Stack>

            <CreateModerableSourceDialog
                open={isDialogOpen}
                handleClose={() => setDialogOpen(false)}
            />
        </DashboardBox>
    );
}
