import React from "react";
import { Filter, NumberInput, DateInput, SearchInput, SelectInput, BooleanInput, useTranslate, useListContext, Button } from "react-admin";
import { useSettingsContext } from "../../utils/SettingsProvider";
import LanguageSelectInput from "../../inputs/LanguageSelectInput";
import AutocompleteReferenceInput from "../../inputs/AutocompleteReferenceInput";
import RadioButtonUncheckedIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioButtonCheckedIcon from '@mui/icons-material/CheckBox';

const ArgumentFilter = (props) => {
	const translate = useTranslate();
	const settingsContext = useSettingsContext();

	return (
		<Filter {...props}>
			<SearchInput source='query' alwaysOn variant={"outlined"} />
			<BooleanInput source='is_reply' label={translate("resources.messages.fields.is_reply")} variant={"outlined"} />
			<NumberInput source='score_gte' step={1} label={translate("resources.filters.from_score")} variant={"outlined"} />
			<NumberInput source='score_lte' step={1} label={translate("resources.filters.to_score")} variant={"outlined"} />
			<DateInput
				source='created_at_gte'
				alwaysOn
				label={translate("resources.filters.from_date")}
				variant={"outlined"}
			/>
			<DateInput
				source='created_at_lte'
				alwaysOn
				label={translate("resources.filters.to_date")}
				variant={"outlined"}
			/>
			<SelectInput
				style={{ marginBottom: "0" }}
				source='status'
				resettable
				choices={[
					{ id: "accepted", name: translate("resources.messages.status.accepted") },
					{ id: "pending", name: translate("resources.messages.status.pending") },
					{ id: "rejected", name: translate("resources.messages.status.rejected") },
				]}
				alwaysOn
				variant={"outlined"}
			/>
			{settingsContext?.settings?.translation?.enable === true &&
				<LanguageSelectInput
					resettable
					alwaysOn
					style={{ marginBottom: "0" }}
				/>
			}
			<SelectedFilter alwaysOn />
			<AutocompleteReferenceInput
				source="user_id"
				reference="users"
				sort={{ field: 'created_at', order: 'DESC' }}
				label={translate('resources.messages.fields.author')}
				optionText={choice => `${choice.full_name} [${choice.id}]`}
				optionValue={"identifier"}
				alwaysOn
			/>
			<AutocompleteReferenceInput
				source="group_id"
				reference={props.groupType}
				sort={{ field: 'created_at', order: 'DESC' }}
				filters={{ is_published: true }}
				label={translate('resources.messages.fields.origin')}
				optionText={choice => `${props.groupType === "groups" ? choice.name : choice.title}`}
				optionValue={"identifier"}
				alwaysOn
			/>
		</Filter >
	);
};

const SelectedFilter = () => {
	const { setFilters, filterValues } = useListContext();
	const translate = useTranslate();
	return (
		<Button
			label={translate("resources.messages.fields.selected")}
			onClick={() => filterValues["score"] ? setFilters({ ...filterValues, "score": undefined }) : setFilters({ ...filterValues, "score": 99 })}
			style={{ padding: "9px 14px", border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "4px", marginTop: "8px", color: "rgba(0, 0, 0, 0.6)" }}
			alignIcon="left"
		>
			{filterValues["score"] === 99 ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
		</Button>
	);
};

export default ArgumentFilter;
