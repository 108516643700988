import React, { useState } from 'react';
import { ExposeMotifs } from './ExposeMotifs';
import TexteBox from './TexteBox';
import { Agenda } from './Agenda';
import { AmendementList } from './AmendementList';
import styles from './ActeLegislatifDetails.module.scss';

export const ActeLegislatifDetails = ({ acte, document, actesLegislatifs, selectedActe, exposeMotifsTexte }) => {
  const [subdivisionRefHash, setSubdivisionRefHash] = useState("");

  const handleAmendementButtonClick = (subdivision) => {
    setSubdivisionRefHash(subdivision.hash);
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.top}>
          <ExposeMotifs document={document} text={exposeMotifsTexte} />
          <Agenda actesLegislatifs={actesLegislatifs} selectedActe={selectedActe} />
        </div>

        <div className={styles.bottom}>
          <TexteBox document={document} onAmendementButtonClick={handleAmendementButtonClick} />
          <AmendementList
            key={subdivisionRefHash}
            document={document}
            subdivisionRefHash={subdivisionRefHash}
          />
        </div>
      </div>
    </div>
  );
};
