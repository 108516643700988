import React from 'react';
import { DashboardBox } from '../dashboard/DashboardBox';
import { TrendingDown, TrendingFlat, TrendingUp } from '@mui/icons-material';
import { Box, Icon, Stack, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';

export function MetricDashboardBox({
    title,
    icon,
    metric,
    trend,
    trendValue,
    comingSoon = false
}: {
    title: string;
    icon: React.FC;
    metric: string;
    trend: 'up' | 'down' | 'flat';
    trendValue: string;
    comingSoon?: boolean;
}) {
    const translate = useTranslate();

    const getTrendIcon = () => {
        if (comingSoon) {
            return TrendingFlat;
        }

        switch (trend) {
            case 'up':
                return TrendingUp;
            case 'down':
                return TrendingDown;
            case 'flat':
                return TrendingFlat;
            default:
                return TrendingUp;
        }
    };

    const getTrendColor = () => {
        if (comingSoon) {
            return 'var(--clr-primary-200)';
        }

        switch (trend) {
            case 'up':
                return 'var(--clr-success)';
            case 'down':
                return 'var(--clr-error)';
            case 'flat':
                return 'var(--clr-primary-200)';
            default:
                return 'var(--clr-neutral-0)';
        }
    };

    return (
        <DashboardBox
            title={title}
            icon={icon}
            borderRadius="sm"
            menu={
                comingSoon ? (
                    <Box
                        sx={{
                            backgroundColor: '#d8218f',
                            color: 'white',
                            padding: '2px 8px',
                            borderRadius: '6px'
                        }}
                    >
                        {translate('pos.social_networks.coming_soon')}
                    </Box>
                ) : null
            }
        >
            <Typography
                fontSize={36}
                color="var(--clr-neutral-0)"
                fontWeight={600}
            >
                {comingSoon ? '--' : metric}
            </Typography>

            <Stack direction="row" alignItems="flex-start" gap={0.5}>
                <Stack direction="row" alignItems="center" gap={0.5}>
                    <Icon
                        component={getTrendIcon()}
                        sx={{ color: getTrendColor() }}
                    />
                    <Typography fontWeight={600} color={getTrendColor()}>
                        {comingSoon ? '--' : trendValue}
                    </Typography>
                </Stack>
                {comingSoon
                    ? ''
                    : translate(
                          'pos.social_networks.dashboard.metrics.since_previous_period'
                      )}
            </Stack>
        </DashboardBox>
    );
}
