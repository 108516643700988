import React from 'react';
import { Close } from '@mui/icons-material';
import {
    Dialog,
    Stack,
    DialogTitle,
    DialogActions,
    DialogContent,
    Box
} from '@mui/material';
import { PlatformOAuth2Card } from '../platforms/PlatformOAuth2Card';
import { useTranslate } from 'react-admin';
import styles from './CreateModerableSourceDialog.module.scss';

const platforms = [
    {
        name: 'instagram',
        description:
            'Connect your Facebook account to moderate your posts and comments on Facebook.',
        authUrl: 'https://www.instagram.com/oauth/authorize',
        clientId: process.env.REACT_APP_INSTAGRAM_CLIENT_ID as string,
        scope: 'instagram_business_basic,instagram_business_manage_comments',
        forceAuth: true
    },
    {
        name: 'facebook',
        description:
            'Connect your Facebook account to moderate your posts and comments on Facebook.',
        authUrl: 'https://www.facebook.com/v9.0/dialog/oauth',
        clientId: process.env.REACT_APP_FACEBOOK_CLIENT_ID as string,
        scope: 'pages_manage_engagement,pages_read_engagement,pages_read_user_content,pages_manage_metadata,pages_show_list,read_insights'
    },
    {
        name: 'youtube',
        description:
            'Connect your Facebook account to moderate your posts and comments on Facebook.',
        authUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
        clientId: process.env.REACT_APP_GOOGLE_MODERATION_CLIENT_ID as string,
        scope: 'https://www.googleapis.com/auth/youtube.force-ssl',
        accessType: 'offline'
    }
] as const;

export function CreateModerableSourceDialog({
    open = false,
    handleClose = () => {}
}: {
    open?: boolean;
    handleClose: () => void;
}) {
    const translate = useTranslate();

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
            >
                <DialogTitle sx={{ fontWeight: 600 }}>
                    {translate('pos.social_networks.add_source.dialog_title')}
                </DialogTitle>

                <DialogActions>
                    <button onClick={handleClose} className={styles.closeBtn}>
                        <Close />
                    </button>
                </DialogActions>
            </Stack>

            <p className={styles.description}>
                {translate('pos.social_networks.add_source.dialog_subtitle')}
            </p>

            <DialogContent>
                <Box
                    display="grid"
                    gridTemplateColumns="repeat(auto-fill, minmax(300px, auto))"
                    gap={2}
                >
                    {platforms.map((platform) => (
                        <PlatformOAuth2Card platformInfos={platform} />
                    ))}
                </Box>
            </DialogContent>
        </Dialog>
    );
}
